import React from 'react';

import PortfolioProjectData from '../../../data/portfolio/JohnAndTammy/portfolioProjectData_JohnAndTammy';

function PortfolioProject() {
    return (
        <section id="about-the-project" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>About the Wedding</h2>
                </div>

                <div className="row gutter-width-md with-pb-md">   
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div className="project-info">
                            <div className="project-info-items">
                                { PortfolioProjectData && PortfolioProjectData.map( ( item, key ) => {
                                    return (
                                        <div key={ key } className="project-info-item">
                                            <div className="project-info-item-content">
                                                <div className="img object-fit">
                                                    <div className="object-fit-cover">
                                                        <img src={ item.imgSrc } alt={ item.title } />
                                                    </div>
                                                </div>

                                                <div className="project-info-item-description">
                                                    <h5 className="project-info-title after">{ item.title }</h5>
                                                    <p className="project-info-description">{ item.description }</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div className="description">
                            <h4 className="max-width-3">Let me introduce you this amazing wedding project.</h4>
                            <p>
                            John and Tammy, a stunning couple whose love seemed like a fairytale come to life, embarked 
                            on a journey of traditional matrimony that left all in awe. As they stood at the altar, 
                            their eyes locked in a gaze that spoke volumes of their devotion, it was evident that their 
                            souls had found their perfect counterpart. John, with his chiseled features and a warm 
                            smile that could melt hearts, exuded a timeless charm that complemented Tammy's radiance. Tammy, 
                            a vision of elegance in her flowing white gown, possessed a grace that mirrored the gentle 
                            sway of a willow tree in the breeze.
                            <br /><br />
                            Their traditional marriage was a masterpiece, perfectly executed with meticulous attention to 
                            detail. The ceremony was a symphony of love and tradition, with vows exchanged amidst the timeless 
                            splendor of a grand cathedral. Their families beamed with pride as they witnessed the union of 
                            two hearts destined to be together. From the classic wedding march to the elegant decor, every 
                            element reflected their shared values and the beauty of their love. As they embraced as husband 
                            and wife, the atmosphere was filled with an aura of joy and celebration, sealing their love in a 
                            timeless bond that would stand the test of time. John and Tammy's wedding became a cherished 
                            memory in the hearts of all who attended, a reminder that traditional love stories can still 
                            captivate the soul and leave an everlasting impression on all who witness them.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioProject;
