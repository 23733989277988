import React from 'react';

const PageTitleHome = () => {
    return (
        <section id="page-title" className="block">
            <div className="wrapper">
                <div className="page-title-content">
                    <div className={"d-flex justify-content-center"}>
                        <video controls="true" width="100%" height="auto" src="https://prayingphotographer.blob.core.windows.net/videos/vid_4.mov" loop autoPlay="true" muted playsinline disablepictureinpicture preload="auto"/>
                    </div>
                    <div className="page-title-description">
                        <div className="title">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PageTitleHome;
