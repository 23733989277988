import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleHome from '../blocks/page-title/PageTitleHome';
import AboutServices from '../blocks/about/AboutServices';
import HomePortfolio from '../blocks/home/HomePortfolio';
import ContactMe from '../blocks/home/ContactMe';
import HomeBlog from '../blocks/home/HomeBlog';
import ContactMe2 from '../blocks/contacts/ContactMe2';


const Home = () => {
    document.body.classList.add( 'home' );

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>thePrayingPhotographer</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="Wedding photography,Engagement photos,Wedding photographers near me,Best wedding photographers,Wedding photo ideas,Bridal photoshoot,Destination wedding photography,Wedding photography packages,Wedding portrait,Wedding photography tips,Candid wedding photos,Professional wedding photos,Wedding photo albums,Wedding photo booth,Pre-wedding photoshoot,Wedding photography styles,Wedding photo poses,Best engagement photo locations,Luxury wedding photography,Affordable wedding photographers,Wedding videography,Wedding photo and video packages,Outdoor wedding photos,Beach wedding photography,Wedding ceremony photos,Wedding reception photography,Wedding photography checklist,Candid wedding photography,Traditional wedding photos,Black and white wedding photos" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main">
                <div className="content">
                    <div className="clearfix">
                        <PageTitleHome />

                        <div id="services" className="block spacer p-top-xl">
                            <div className="wrapper">
                                <div className="title">
                                    <h2><a href="/Services">Services</a></h2>
                                </div>

                                <AboutServices />
                            </div>
                        </div>

                        <HomePortfolio />

                        <ContactMe />
                        
                        <HomeBlog />

                        <section id="contact-me-2" className="block spacer p-top-xl">
                            <div className="wrapper">
                                <ContactMe2 />
                            </div>
                        </section>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Home;
