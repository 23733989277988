import React from 'react';

const PageTitleAbout = () => {
    return (
        <section id="page-title">
            <div className="wrapper">
                <div className="title">
                    <h1 className="large">Services</h1>
                </div>
                <br /><br /><br />
                <div className="row gutter-width-md with-pb-md">
                    <div className="description-3 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                        <h3><b>Fee Structure</b></h3>
                        <p><b>1. Basic - $2,500</b><br />
                        Coverage: Up to 6 hours on the wedding day.<br />
                        Photos: Approximately 300-400 edited high-resolution images<br />
                        Online Gallery: Password-protected online gallery for viewing, sharing, and downloading photos.<br />
                        USB Drive: All high-resolution images delivered on a custom USB drive.<br />
                        Print Rights: Personal print release allowing you to print your photos as you choose.<br />
                        <br /><br />
                        <b>2. Extended - $3,500</b><br />
                        Coverage: Up to 8 hours on the wedding day<br />
                        Engagement Session: 1-hour engagement photo session with 50 edited images.<br />
                        Photos: Approximately 400-600 edited high-resolution images.<br />
                        Online Gallery: Password-protected online gallery for viewing, sharing, and downloading photos.<br />
                        USB Drive: All high-resolution images from both wedding and engagement sessions delivered on a custom USB drive.<br />
                        Album: 10x10 inch quality photo album with selected images (around 20 pages).<br />
                        Print Rights: Personal print release allowing you to print your photos as you choose.<br />
                        <br /><br />
                        <b>3. Bourgeoisie - $6,500</b><br />
                        Coverage: Full day coverage, up to 12 hours.<br />
                        Engagement Session: 2-hour engagement photo session with 100 edited images.<br />
                        Second Shooter: An additional photographer for the wedding day to ensure multiple angles and more coverage.<br />
                        Photos: Approximately 600-900 edited high-resolution images.<br />
                        Online Gallery: Password-protected online gallery for viewing, sharing, and downloading photos.<br />
                        USB Drive: All high-resolution images from both wedding and engagement sessions delivered on a custom USB drive.<br />
                        Premium Album: 12x12 inch premium leather-bound photo album with selected images (around 30 pages).<br />
                        Print Credit: $200 print credit to purchase prints or canvases.<br />
                        Print Rights: Personal print release allowing you to print your photos as you choose.<br />
                        Post-Wedding Shoot: 1-hour session after the wedding, which could be a "trash the dress" or just additional 
                        couple shots in a different location.<br /></p>
                    </div>
                    <div className="description-2 column" style={{ paddingTop: "0px", textAlign: "Justify"}}>   
                        <h3><b>What I Do</b></h3> 
                        <p>As a wedding photographer, my services encompass a diverse range of moments and events. My 
                        expertise includes capturing the essence of love and joy through wedding photography, beautifully 
                        documenting the special day of couples as they start their journey together. Additionally, I offer 
                        Honeymoon Photography, which allows couples to cherish their first adventures as a married pair.
                        <br /><br />
                        My skills extend beyond weddings, as I also provide Anniversary Photography, immortalizing the 
                        enduring love and memories of couples celebrating their years together. For expectant parents, I 
                        offer Maternity Photography, capturing the precious moments of anticipation and excitement before 
                        the arrival of a new family member.
                        <br /><br />
                        Furthermore, my talents extend to Event Photography, ensuring clients have stunning visual records 
                        of various occasions beyond weddings, such as birthdays, corporate gatherings, and family reunions. Lastly, 
                        I cater to the academic achievements of graduates with Graduation Photography, preserving the proud 
                        moments of students as they step into the next phase of their lives.
                        <br /><br />
                        Overall, my photography services cater to love, milestones, and celebrations, offering clients the 
                        opportunity to cherish these precious moments for a lifetime.</p>
                        <br />
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="https://prayingphotographer.blob.core.windows.net/pictures/mainImgs/Services.jpg" alt="UpClose" />
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PageTitleAbout;
