import React from 'react';

const AboutContent = () => {
    return (
        <div id="about" className="block spacer p-top-xl">
            <div className="title">
                <h2>About me</h2>
            </div>

            <div className="row gutter-width-md with-pb-md">
                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <h5>I'm Josh. I’m a wedding, anniversary and honeymoon photographer.</h5>
                </div>

                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                    <p>
                    For 15 transformative years, I have been illuminating life's moments and turning them into lasting 
                    memories through the power of photography. My journey began with an intense fascination for light 
                    and colors, which inspired me to traverse the vast landscape of photography, learning to capture 
                    the world in a new and unique way. Over the years, my work has spanned a multitude of genres, 
                    from ethereal landscapes and intense portraiture to dynamic event photography and timeless weddings. 
                    This wide-ranging experience has not only honed my skills but also allowed me to understand and express 
                    diverse perspectives through my lens, resulting in compelling imagery that genuinely reflects the 
                    essence of the moment and the character of the subject.
                    <br /><br />
                    My artistic journey is about more than just taking beautiful photos. It's about building a deep 
                    connection with my clients, understanding their story, and reflecting their vision. My clients 
                    often praise my ability to make them feel at ease in front of the camera and appreciate the passion 
                    and dedication I bring to each project. Whether you're looking for a portrait that captures your 
                    spirit, a wedding album that tells your unique love story, or event photography that encapsulates 
                    the energy and emotion of the day, I'm committed to delivering an outcome that not only meets your 
                    expectations but exceeds them. With a keen eye for detail, a distinctive style, and a firm belief 
                    in the power of collaboration, I look forward to helping you create lasting memories.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AboutContent;
