import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleAbout from '../blocks/page-title/PageTitleAbout';
import AboutContent from '../blocks/about/AboutContent';
import AboutServices from '../blocks/about/AboutServices';
import AboutPortfolioLink from '../blocks/about/AboutPortfolioLink';

const About = () => {
    document.body.classList.add( 'page' );

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>About thePrayingPhotographer</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="Atlanta wedding photography, Hindu wedding photographer Atlanta, Vedic ceremonies Atlanta, Candid wedding captures Atlanta, Atlanta sangeet photography, Traditional wedding photos Atlanta, Mehndi ceremony photos Atlanta, Intimate bridal portraits Atlanta, Baraat procession captures Atlanta, Atlanta destination wedding, Saat phere shots Atlanta, Best Atlanta wedding photographers, Hindu rituals specialist Atlanta, Wedding album designs Atlanta, Pre-wedding shoots Atlanta, Luxury wedding photos Atlanta, Haldi ceremony moments Atlanta, Contemporary Atlanta wedding photos, Mandap ceremony photography Atlanta, Wedding attire shots Atlanta, Outdoor Atlanta wedding, Story-telling wedding photos Atlanta, Artistic décor shots Atlanta, Atlanta couple portraits, Diverse wedding styles Atlanta, Classic southern wedding photos, Hindu engagement photos Atlanta, Downtown Atlanta wedding photography, Atlanta reception captures, Traditional and modern blend Atlanta" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main">
                <PageTitleAbout />

                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div className="clearfix">
                                <div id="about-img" className="block">
                                    <div className="about-img">
                                        <div className="img object-fit">
                                            <div className="object-fit-cover">
                                                <img src="https://prayingphotographer.blob.core.windows.net/pictures/mainImgs/My_Temp_Pic.jpg" alt="About" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <AboutContent />

                                <div id="services" className="block spacer p-top-xl">
                                    <div className="title">
                                        <h2>What I do?</h2>
                                    </div>

                                    <AboutServices />
                                </div>

                                <AboutPortfolioLink />
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default About;
