import React from 'react';

import PortfolioProjectData from '../../../data/portfolio/JohnAndKim/portfolioProjectData_JohnAndKim';

function PortfolioProject() {
    return (
        <section id="about-the-project" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>About the project</h2>
                </div>

                <div className="row gutter-width-md with-pb-md">   
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div className="project-info">
                            <div className="project-info-items">
                                { PortfolioProjectData && PortfolioProjectData.map( ( item, key ) => {
                                    return (
                                        <div key={ key } className="project-info-item">
                                            <div className="project-info-item-content">
                                                <div className="img object-fit">
                                                    <div className="object-fit-cover">
                                                        <img src={ item.imgSrc } alt={ item.title } />
                                                    </div>
                                                </div>

                                                <div className="project-info-item-description">
                                                    <h5 className="project-info-title after">{ item.title }</h5>
                                                    <p className="project-info-description">{ item.description }</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div className="description">
                            <h4 className="max-width-3">John & Kim</h4>
                            <p>
                                John and Kim's love story is the kind of tale that stands as a testament to enduring 
                                love, a romance that began in the labyrinth of high school hallways and bloomed 
                                amidst the pages of textbooks and whispers of youth. Their eyes first met over a 
                                shared geometry book, and in the symphony of school bells and laughter, they discovered 
                                a connection that transcended the typical high-school crush. Their bond was immediate, 
                                profound, and pure; it was as if two halves of the same soul had finally found each 
                                other. From shared lunches and stolen glances to late-night study sessions and prom 
                                night dances, their relationship blossomed, creating a beautifully intertwined tapestry 
                                of love and shared dreams that became the envy of their peers.
                                <br /><br />
                                The passage of time only deepened their love, transforming the adolescent infatuation 
                                into a mature and timeless bond that continues to thrive. Their shared history is 
                                visible in the comfortable ease with which they exist in each other's presence, in 
                                their shared laughter and understanding glances, in the way John's eyes still light 
                                up at the sight of Kim and the affectionate way she reaches for his hand. Today, 
                                they stand as a testament to the possibility of finding your forever person in the 
                                early years of life, weathering the ups and downs of life together while their 
                                love story continues to unfold. They are not just high school sweethearts; they 
                                are life partners, two people who discovered their perfect match amidst the chaos 
                                of adolescence and never let go.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioProject;
