import React from 'react';
import { PrismCode } from '../../../../../components/prsimjs/Prismjs';

const DisabledItems = () => {
    return (
        <div id="disabled-items">
            <h4>Disabled items</h4>
            <p>Add <code>.disabled</code> to a <code>.list-group-item</code> to make it <em>appear</em> disabled. Note that some elements with <code>.disabled</code> will also require custom JavaScript to fully disable their click events (e.g., links).</p>

            <div className="bd-example">

            </div>

            <PrismCode />
        </div>
    );
};

export default DisabledItems;
