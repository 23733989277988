import React from 'react';

import PortfolioProjectData from '../../../data/portfolio/SamanthaAndThomas/portfolioProjectData_SamanthaAndThomas';

function PortfolioProject() {
    return (
        <section id="about-the-project" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>About the project</h2>
                </div>

                <div className="row gutter-width-md with-pb-md">   
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div className="project-info">
                            <div className="project-info-items">
                                { PortfolioProjectData && PortfolioProjectData.map( ( item, key ) => {
                                    return (
                                        <div key={ key } className="project-info-item">
                                            <div className="project-info-item-content">
                                                <div className="img object-fit">
                                                    <div className="object-fit-cover">
                                                        <img src={ item.imgSrc } alt={ item.title } />
                                                    </div>
                                                </div>

                                                <div className="project-info-item-description">
                                                    <h5 className="project-info-title after">{ item.title }</h5>
                                                    <p className="project-info-description">{ item.description }</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div className="description">
                            <h4 className="max-width-3">Sam & Tom's Wedding</h4>
                            <p>
                                Once upon a time, in a charming town nestled amidst rolling green hills, a 
                                young couple named Samantha and Thomas prepared to embark on the most exciting 
                                journey of their lives. Their love story, which had blossomed over stolen glances 
                                in the college library and sweet shared laughter under the starlit sky, had led 
                                them to this unforgettable moment: their wedding day. They had always dreamt of a 
                                celebration that would be as unique and beautiful as their love, and on a warm 
                                summer afternoon, under a sky painted with hues of orange and pink, they were all 
                                set to turn their dream into reality.
                                <br /><br />
                                Their chosen venue, a historic barn, had been magically transformed into a 
                                romantic haven, adorned with twinkling fairy lights and elegant white roses, 
                                their fragrance filling the air with a sense of enchantment. Samantha, radiant 
                                in a delicate lace gown, walked down the aisle lined with petals, her eyes 
                                glistening with the reflection of their shared dreams and unspoken promises. Thomas, 
                                dapper in a classic black tuxedo, looked at Samantha with a warmth that outshone the 
                                soft, glowing candles around them. Their loved ones watched, teary-eyed and joyful, 
                                as they exchanged rings and vows, their voices unwavering, filled with devotion. As 
                                they sealed their promises with a tender kiss, the room erupted in cheers, marking 
                                the beginning of their happily ever after. Amidst the whirl of dancing, toasting, 
                                and jubilant laughter that followed, the newlyweds held onto each other, their 
                                eyes sparkling brighter than the stars above, knowing they had just lived the most 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioProject;
