import React from 'react';

import PortfolioProjectData from '../../../data/portfolio/LeslieAndAndy/portfolioProjectData_LeslieAndAndy';

function PortfolioProject() {
    return (
        <section id="about-the-project" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>About the Anniversary</h2>
                </div>

                <div className="row gutter-width-md with-pb-md">   
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div className="project-info">
                            <div className="project-info-items">
                                { PortfolioProjectData && PortfolioProjectData.map( ( item, key ) => {
                                    return (
                                        <div key={ key } className="project-info-item">
                                            <div className="project-info-item-content">
                                                <div className="img object-fit">
                                                    <div className="object-fit-cover">
                                                        <img src={ item.imgSrc } alt={ item.title } />
                                                    </div>
                                                </div>

                                                <div className="project-info-item-description">
                                                    <h5 className="project-info-title after">{ item.title }</h5>
                                                    <p className="project-info-description">{ item.description }</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div className="description">
                            <h4 className="max-width-3">Lelie & Andy</h4>
                            <p>
                            In the romantic city of Florence, Leslie and Andy's love story reached its crescendo as they celebrated 
                            an unforgettable Italian wedding that would forever be etched in their hearts. Leslie, with her vivacious 
                            spirit and a smile that could light up the Ponte Vecchio, found her soulmate in Andy, whose endearing 
                            humor and unwavering support made every day brighter than the last. Surrounded by the timeless beauty 
                            of Renaissance architecture and the gentle whispers of the Arno River, their love seemed to mirror the 
                            artistry of Michelangelo's masterpieces.
                            <br /><br />
                            Their wedding day was a perfect fusion of Italian elegance and heartfelt emotions. With a charming 
                            villa as the backdrop and a Tuscan feast to delight their guests, the day unfolded like a dream. As 
                            they exchanged heartfelt vows in an intimate courtyard, tears of joy glistened in their eyes, reflecting 
                            the love they held for each other. Friends and family from near and far gathered to celebrate Leslie and 
                            Andy's union, and the air was filled with laughter and the clinking of wine glasses. As the evening 
                            sky turned to a canvas of stars, Leslie and Andy danced the night away, surrounded by the warmth of 
                            love and the enchantment of Italy, marking the beginning of a new chapter in their love story that would 
                            forever bloom like the blossoming gardens of Florence.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioProject;
