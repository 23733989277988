import React from 'react';

import PortfolioProjectData from '../../../data/portfolio/PalakAndVarun/portfolioProjectData_PalakAndVarun';

function PortfolioProject() {
    return (
        <section id="about-the-project" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>About the Wedding</h2>
                </div>

                <div className="row gutter-width-md with-pb-md">   
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div className="project-info">
                            <div className="project-info-items">
                                { PortfolioProjectData && PortfolioProjectData.map( ( item, key ) => {
                                    return (
                                        <div key={ key } className="project-info-item">
                                            <div className="project-info-item-content">
                                                <div className="img object-fit">
                                                    <div className="object-fit-cover">
                                                        <img src={ item.imgSrc } alt={ item.title } />
                                                    </div>
                                                </div>

                                                <div className="project-info-item-description">
                                                    <h5 className="project-info-title after">{ item.title }</h5>
                                                    <p className="project-info-description">{ item.description }</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div className="description">
                            <h4 className="max-width-3">Palak & Varun</h4>
                            <p>
                                In the radiant land of color and tradition, the union of Palak and Varun has woven 
                                a magnificent story of love and kinship. Meeting through the unexpected thread of 
                                their siblings' camaraderie, this couple discovered their soul's reflection in each 
                                other's eyes. Their story, not of a chance encounter but a serendipitous connection, 
                                evolved amid shared laughter, cherished family moments, and a mutual understanding 
                                that whispered softly of a shared future. Their connection ignited into a love that 
                                couldn’t be ignored, a love that reverberated through the echo of their shared 
                                dreams and resonated with the rhythm of their synchronized heartbeats.
                                <br /><br />
                                Earlier this year, Palak and Varun vowed eternal love and commitment, celebrated in 
                                the presence of loved ones amid the enchanting grandeur of an Indian wedding. Their 
                                ceremony was a vivid mélange of rich traditions, vibrant colors, and joyful melodies, 
                                echoing the true essence of their love. A spectacular kaleidoscope of swirling lehengas, 
                                glittering jewels, mesmerizing henna art, and the sacred bond of the Mangalsutra 
                                marked their transition into marital bliss. As they exchanged garlands under the floral 
                                canopy, the air filled with the sweet scent of marigolds, and cheers of joy celebrated 
                                this love story that was nothing short of a beautiful destiny.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioProject;
