import React from 'react';

const PageTitleContacts = () => {
    return (
        <section id="page-title">
            <div className="wrapper">
                <div className="title">
                    <h1 className="large">Contact</h1>
                </div>

                <div className="description-2">
                    <p>Ready to transform fleeting moments into everlasting memories? Get 
                    in touch, and let's create magic together!</p>
                </div>
            </div>
        </section>
    );
};

export default PageTitleContacts;
