import React from 'react';
import '../../assets/css/custom.css';

const HeaderLogo = ( { image } ) => {
    return (
        <div className="header-logo" style={{paddingTop: "25px" }}>
            <a className="logo logo-primary transform-scale-h" style={{fontFamily: 'Playfair Display'}} title="Logo" href={ process.env.PUBLIC_URL + "/" }>
                <img className="desktop" src="assets/img/logo/logo.png" alt="Logo" style={{ height: "120px", width: "640px"}} />
                <img className="mobile" src="assets/img/favicon/icon.png" alt="Logo" style={{ height: "50px", width: "50px"}} />
            </a>
        </div>
    );
};

export default HeaderLogo;
