import React from 'react';

const PageTitlePortfolio = () => {
    return (
        <section id="page-title">
            <div className="wrapper">
                <div className="title">
                    <h1 className="large">My Portfolio</h1>
                </div>

                <div className="description-2">
                    <p>Specializing in a candid and journalistic approach, we 
                    capture the natural moments and raw emotions of your wedding 
                    day, telling your unique love story through striking, h
                    igh-quality images.</p>
                </div>
            </div>
        </section>
    );
};

export default PageTitlePortfolio;
