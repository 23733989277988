import React from 'react';

import PortfolioProjectData from '../../../data/portfolio/FenilAndKinjal/portfolioProjectData_FenilAndKinjal';

function PortfolioProject() {
    return (
        <section id="about-the-project" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>About the Wedding</h2>
                </div>

                <div className="row gutter-width-md with-pb-md">   
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div className="project-info">
                            <div className="project-info-items">
                                { PortfolioProjectData && PortfolioProjectData.map( ( item, key ) => {
                                    return (
                                        <div key={ key } className="project-info-item">
                                            <div className="project-info-item-content">
                                                <div className="img object-fit">
                                                    <div className="object-fit-cover">
                                                        <img src={ item.imgSrc } alt={ item.title } />
                                                    </div>
                                                </div>

                                                <div className="project-info-item-description">
                                                    <h5 className="project-info-title after">{ item.title }</h5>
                                                    <p className="project-info-description">{ item.description }</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div className="description">
                            <h4 className="max-width-3">Fenil & Kinjal</h4>
                            <p>
                                Fenil and Kinjal's story is a symphony of ambition, passion, and serendipity. Hailing 
                                from different corners of India, both were driven by a dream to leave an indelible 
                                mark in the field of engineering. Their individual journeys took them from the bustling 
                                streets of their hometowns to the best universities in the country, and eventually to 
                                a prestigious engineering firm abroad. Their diligence, hard work, and dedication to 
                                their craft were evident in their early successes, painting them as two of the most 
                                promising engineers of their generation.
                                <br /><br />
                                It was amidst the intricate designs and blueprints, in the heart of this high-paced 
                                technological haven, that their paths serendipitously crossed. An initial exchange of 
                                technical opinions swiftly evolved into deep conversations about their shared dreams, 
                                cultures, and the challenges of establishing themselves in a new world. Their bond was 
                                undeniable, not just as two gifted engineers, but as two souls who had traversed 
                                similar journeys, braved parallel challenges, and were now intertwined by fate and 
                                mutual admiration. Their partnership flourished both in boardrooms and beyond, 
                                making them not only the power couple in the corridors of the firm but also a 
                                testament to the beauty of finding love when and where you least expect it.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioProject;
