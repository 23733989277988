import React from 'react';

const PageTitlePortfolioInside = () => {
    return (
        <section id="page-title" className="block">
            <div className="wrapper">
                <div className="">
                    <div className="">
                        <img src="https://prayingphotographer.blob.core.windows.net/pictures/mainImgs/portImg4_1.jpg" alt="Happy Anniversary" />
                    </div>

                    <div className="page-title-description">
                        <div className="title">
                        </div>

                        <div className="description">
                            <p>Joshua & Laura</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PageTitlePortfolioInside;