import React from 'react';

import PortfolioProjectData from '../../../data/portfolio/JoshuaAndLaura/portfolioProjectData_JoshuaAndLaura';

function PortfolioProject() {
    return (
        <section id="about-the-project" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>About the Honeymoon</h2>
                </div>

                <div className="row gutter-width-md with-pb-md">   
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div className="project-info">
                            <div className="project-info-items">
                                { PortfolioProjectData && PortfolioProjectData.map( ( item, key ) => {
                                    return (
                                        <div key={ key } className="project-info-item">
                                            <div className="project-info-item-content">
                                                <div className="img object-fit">
                                                    <div className="object-fit-cover">
                                                        <img src={ item.imgSrc } alt={ item.title } />
                                                    </div>
                                                </div>

                                                <div className="project-info-item-description">
                                                    <h5 className="project-info-title after">{ item.title }</h5>
                                                    <p className="project-info-description">{ item.description }</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div className="description">
                            <h4 className="max-width-3">Joshua & Laura</h4>
                            <p>
                                In the ethereal glow of the setting sun, Joshua and Laura embarked on a journey of 
                                love and discovery as they began their honeymoon. They found themselves whisked away 
                                to the idyllic landscapes of Bali, where pristine beaches met the azure sea, and lush 
                                terraces of emerald green rice fields stretched as far as the eye could see. Here, 
                                they found a paradisiacal world where time stood still, allowing them to immerse themselves 
                                in the sublime joy of their newfound marital bliss. Every day brought with it a new 
                                adventure - from exploring the ancient temples that held centuries of history, to 
                                avigating through vibrant local markets, their hands interlocked as if they were 
                                navigating life's joys and wonders together.
                                <br /><br />
                                Their evenings were spent beneath a tapestry of twinkling stars, indulging in candlelit 
                                dinners on the beach, their laughter mingling with the soft whispers of the ocean waves. 
                                It was during these moments, with sand beneath their feet and the salty sea air in their 
                                hair, that they truly connected on a level deeper than they ever had before. They shared 
                                dreams and fears, laughter and tears, each moment further strengthening the bond that 
                                had led them to each other. From tranquil sunrises to breathtaking sunsets, Joshua and 
                                Laura's honeymoon was not just an unforgettable vacation but a journey of discovery - a 
                                reaffirmation of their love, a testament to their commitment, and an exciting glimpse 
                                into a future filled with endless possibilities.
                            </p>                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioProject;
