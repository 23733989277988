import React from 'react';

import PortfolioProjectData from '../../../data/portfolio/EddyAndElizabeth/portfolioProjectData_EddyAndElizabeth';

function PortfolioProject() {
    return (
        <section id="about-the-project" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>About the Wedding</h2>
                </div>

                <div className="row gutter-width-md with-pb-md">   
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div className="project-info">
                            <div className="project-info-items">
                                { PortfolioProjectData && PortfolioProjectData.map( ( item, key ) => {
                                    return (
                                        <div key={ key } className="project-info-item">
                                            <div className="project-info-item-content">
                                                <div className="img object-fit">
                                                    <div className="object-fit-cover">
                                                        <img src={ item.imgSrc } alt={ item.title } />
                                                    </div>
                                                </div>

                                                <div className="project-info-item-description">
                                                    <h5 className="project-info-title after">{ item.title }</h5>
                                                    <p className="project-info-description">{ item.description }</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div className="description">
                            <h4 className="max-width-3">Eddy & Elizabeth</h4>
                            <p>
                            Eddy and Elizabeth, an interracial couple whose love defied societal norms, became an inspiring beacon of 
                            unity and acceptance. In a world that sometimes struggled to embrace diversity, their love soared above 
                            all boundaries. Eddy, with his warm smile and compassionate heart, found in Elizabeth a soulmate whose 
                            vibrant spirit and unwavering strength captivated him. As they faced the challenges that sometimes came 
                            with their interracial relationship, their love only grew stronger, fueled by the understanding that 
                            true love knows no color or creed.
                            <br /><br />
                            Together, they forged a path of acceptance and understanding, championing the power of love to bridge 
                            divides. Their love story became a testament to the beauty of celebrating differences and finding common 
                            ground in the depths of the heart. As they walked hand in hand, they inspired those around them to 
                            embrace the richness of diversity and the strength that comes from unity. Eddy and Elizabeth's journey 
                            taught us all that love is a universal language, capable of breaking down barriers and bringing people 
                            together in a tapestry of love and respect. Theirs was a love that transcended stereotypes and proved 
                            that in the end, the heart sees only what truly matters – love in its purest form.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioProject;
