import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleContacts from '../blocks/page-title/PageTitleContacts';
import ContactsList from '../blocks/contacts/ContactsList';
import ContactMe2 from '../blocks/contacts/ContactMe2';

const Contacts = () => {
    document.body.classList.add( 'page' );

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Contact | ThePrayingPhotographer.com</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="Atlanta wedding photography trends, Best wedding venues in Atlanta, Hindu wedding traditions in Atlanta, Seasonal wedding photo ideas Atlanta, Top engagement photo locations in Atlanta, Candid wedding moments captured in Atlanta, How to prepare for your Atlanta wedding photoshoot, Atlanta wedding photography packages explained, The beauty of outdoor wedding photos in Atlanta, Tips for winter weddings in Atlanta, Atlanta wedding day photography checklist, Choosing between black and white or color photos in Atlanta, The magic of sunset wedding photos in Atlanta, Why Atlanta is a top wedding photography destination, Behind the scenes: An Atlanta wedding photographer's perspective, Atlanta's top floral arrangements captured, Candid vs. posed: Wedding photography styles in Atlanta, Unique wedding traditions in Atlanta, Incorporating Atlanta's skyline into wedding photos, Best season for wedding photography in Atlanta, The charm of vintage wedding photos in Atlanta, Atlanta wedding day photography timeline suggestions, Combining tradition and modernity in Atlanta Hindu weddings, Essential shots every Atlanta wedding photographer should capture, Rainy day wedding photos in Atlanta: Tips and tricks, Cultural diversity in Atlanta wedding photography, Bride and groom portrait ideas in Atlanta, Navigating large wedding venues: Atlanta photographer guide, How to choose your wedding photographer in Atlanta, The storytelling aspect of Atlanta wedding photography" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header/>

            <main id="main" className="site-main">
                <PageTitleContacts />

                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div className="clearfix">
                                <ContactsList />

                                <div id="contact-me-2" className="block spacer p-top-xl">
                                    <ContactMe2 />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Contacts;
