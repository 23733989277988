import React from 'react';

import PortfolioProjectData from '../../../data/portfolio/WilliamAndBethany/portfolioProjectData_WilliamAndBethany';

function PortfolioProject() {
    return (
        <section id="about-the-project" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>About the Wedding</h2>
                </div>

                <div className="row gutter-width-md with-pb-md">   
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div className="project-info">
                            <div className="project-info-items">
                                { PortfolioProjectData && PortfolioProjectData.map( ( item, key ) => {
                                    return (
                                        <div key={ key } className="project-info-item">
                                            <div className="project-info-item-content">
                                                <div className="img object-fit">
                                                    <div className="object-fit-cover">
                                                        <img src={ item.imgSrc } alt={ item.title } />
                                                    </div>
                                                </div>

                                                <div className="project-info-item-description">
                                                    <h5 className="project-info-title after">{ item.title }</h5>
                                                    <p className="project-info-description">{ item.description }</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div className="description">
                            <h4 className="max-width-3">William & Bethany</h4>
                            <p>
                            In the sultry embrace of a summer's eve, William and Bethany, a vivacious and passionate young couple, 
                            set the stage for a scintillating backyard wedding that exuded sensuality at every turn. With eyes 
                            locked in a fiery gaze, William, with his tousled hair and a magnetic aura, felt an undeniable pull 
                            towards Bethany, whose radiant smile and alluring confidence left him mesmerized. Their love was an 
                            inferno of desire, and they decided to embrace the intimacy of a backyard celebration to stoke the 
                            flames of their passion.
                            <br /><br />
                            Amidst the flickering candlelight and the soft glow of string lights, William and Bethany exchanged 
                            vows with a smoldering intensity that left their guests spellbound. Their touches were electric, 
                            sending shivers down each other's spines as they reveled in the intoxicating chemistry between them. 
                            As the night wore on, the pulsating rhythm of the music urged them to move together, their bodies 
                            entwined in a dance of desire. Their wedding was a celebration of love unapologetically expressed, a 
                            tantalizing union that ignited a fire within their souls. Under the stars, they embraced in a stolen 
                            moment, reaffirming their love with a passionate kiss that left no doubt about the irresistible allure 
                            of their energetic backyard union.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioProject;
