import React from 'react';

import PortfolioProjectData from '../../../data/portfolio/JohnAndAmy/portfolioProjectData_JohnAndAmy';

function PortfolioProject() {
    return (
        <section id="about-the-project" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>About the Wedding</h2>
                </div>

                <div className="row gutter-width-md with-pb-md">   
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div className="project-info">
                            <div className="project-info-items">
                                { PortfolioProjectData && PortfolioProjectData.map( ( item, key ) => {
                                    return (
                                        <div key={ key } className="project-info-item">
                                            <div className="project-info-item-content">
                                                <div className="img object-fit">
                                                    <div className="object-fit-cover">
                                                        <img src={ item.imgSrc } alt={ item.title } />
                                                    </div>
                                                </div>

                                                <div className="project-info-item-description">
                                                    <h5 className="project-info-title after">{ item.title }</h5>
                                                    <p className="project-info-description">{ item.description }</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div className="description">
                            <h4 className="max-width-3">John & Amy</h4>
                            <p>
                                In the charming town of Willowbrook, an enchanting love story unfolded as John, a 
                                compassionate and eloquent lawyer, found his perfect match in Amy, a nurturing 
                                and patient school teacher. Their hearts were like two puzzle pieces, fitting 
                                together with ease and grace, creating a beautiful picture of love and understanding. 
                                John's persuasive words in the courtroom were matched only by his tender expressions 
                                of affection for Amy, whose caring nature brought comfort to every student she 
                                touched. Their love bloomed quietly like the soft petals of a blossoming flower, 
                                known only to a select few who saw the magic that sparkled in their eyes whenever 
                                they were together.
                                <br /><br />
                                Bound by the demands of their professions and the pressures of daily life, John and 
                                Amy realized that their happiness lay in each other's embrace. With an unwavering 
                                belief in their love, they decided to seize the moment and elope at the last minute, 
                                escaping to a secluded corner of Willowbrook where their hearts could unfurl freely 
                                like the pages of a favorite book. Under the golden rays of a setting sun, they 
                                exchanged vows in an intimate ceremony, surrounded by the peaceful melody of 
                                chirping birds and rustling leaves.
                                <br /><br />
                                Their elopement was a celebration of love's gentle and kind nature, a testament 
                                to the strength of their bond. As word spread through the town, their friends and 
                                family rejoiced in the newfound joy that radiated from John and Amy's hearts. 
                                Willowbrook embraced them as a symbol of hope, a reminder that love can flourish 
                                even in the most unexpected places. With John's soothing presence and Amy's 
                                nurturing spirit, their love story became a beacon of comfort and inspiration 
                                for all who yearned for a love that was as kind and gentle as the breeze that 
                                danced through the willow trees.
                            </p>                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioProject;
