import React from 'react';

import PortfolioProjectData from '../../../data/portfolio/StuartAndBrinna/portfolioProjectData_StuartAndBrinna';

function PortfolioProject() {
    return (
        <section id="about-the-project" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>About the Wedding</h2>
                </div>

                <div className="row gutter-width-md with-pb-md">   
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div className="project-info">
                            <div className="project-info-items">
                                { PortfolioProjectData && PortfolioProjectData.map( ( item, key ) => {
                                    return (
                                        <div key={ key } className="project-info-item">
                                            <div className="project-info-item-content">
                                                <div className="img object-fit">
                                                    <div className="object-fit-cover">
                                                        <img src={ item.imgSrc } alt={ item.title } />
                                                    </div>
                                                </div>

                                                <div className="project-info-item-description">
                                                    <h5 className="project-info-title after">{ item.title }</h5>
                                                    <p className="project-info-description">{ item.description }</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div className="description">
                            <h4 className="max-width-3">Stuart & Brinna</h4>
                            <p>
                            In the golden glow of a late summer afternoon, Stuart and Brianna's love story reached a poignant climax 
                            as they exchanged vows in a heartfelt wedding ceremony. Both in their 30s, they had each endured the 
                            pain of heartbreak in their past relationships, but fate conspired to bring them together, healing their 
                            wounds with the promise of a love that was worth the wait. Surrounded by the warm embrace of family 
                            and friends who had witnessed their individual journeys of growth, Stuart and Brianna found solace in 
                            each other's arms, knowing that their past heartaches had led them to this moment of profound connection.
                            <br /><br />
                            Their wedding day was a celebration of hope and renewal, a testament to the beauty of love that blossoms 
                            even after enduring hardships. With tears of joy glistening in their eyes, Stuart and Brianna exchanged 
                            vows that were infused with the wisdom and understanding that only comes from healing and self-discovery. 
                            As they danced under the twinkling lights, they knew that their love story was one of triumph and 
                            resilience, proving that love could find its way back into their hearts even after the darkest of times. 
                            Their wedding became a poignant reminder to all present that it is never too late to find a love that 
                            sets the soul on fire and that sometimes, the most beautiful relationships emerge from the ashes of past 
                            heartbreaks.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioProject;
