import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleBlog from '../blocks/page-title/PageTitleBlog';
import BlogContent from '../blocks/blog/BlogContent';
import LoadMoreButton from '../components/loadmore/LoadMore';
import Widget from '../blocks/widget/Widget';

const Journal = () => {
    document.body.classList.add( 'blog' );

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Blog - thePrayingPhotographer</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="Garden Wedding Photography Tips, Wedding Dress Photography Techniques, Wedding Photography Lighting Tricks, Monsoon Wedding Photography Tips, Gothic Wedding Photography Techniques, Evening Wedding Photography Tips, Castle Wedding Photography Guide, Editorial Wedding Photography Techniques, Shadow Play in Wedding Photography, Outdoor Wedding Photography Challenges Solution, Unconventional Wedding Photography Ideas, Twin Wedding Photography Tips, Micro Wedding Photography Guide, Pandemic Wedding Photography Trends 2023, Flamenco Wedding Photography Inspiration, Barn Wedding Photography Tips, Avoidable Wedding Photography Mistakes, Sustainable Wedding Photography Reality, Harmonious Wedding Photography Techniques, Understanding Wedding Photography Contracts, Wedding Group Photography Techniques, Best Time for Wedding Photography, Winter Wonderland Wedding Photography Guide, Fairy Tale Wedding Photography Locations, Hollywood Movie Style Wedding Photography, Latest Wedding Photography Gadgets 2023, Pre-Wedding Photography Checklist, Nightlife Wedding Photography Techniques, Flattering Wedding Photography Poses, Unconventional Wedding Photography Styles 2023." />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main">
                <PageTitleBlog />

                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="blog">
                                <div className="row gutter-width-md">
                                    <div className="col-xl-8 col-lg-8 col-md-12">
                                        <BlogContent />
                                        
                                        <LoadMoreButton />
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-12">
                                        <Widget />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Journal;
