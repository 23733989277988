import React from 'react';

import WidgetSearch from '../widget/WidgetSearch';
import WidgetCategories from '../widget/WidgetCategories';
import WidgetPosts from '../widget/WidgetPosts';

const Widget = () => {
    return (
        <aside id="aside" className="widget-area">
            <WidgetSearch />

            <WidgetCategories />

            <WidgetPosts />
        </aside>
    );
};

export default Widget;
