import React from 'react';

const BlogTags = () => {
    return (
        <div className="tags">
            <p>
                <a title="Photography" href={ process.env.PUBLIC_URL + "/blog" }>Photography</a>
                <a title="Wedding" href={ process.env.PUBLIC_URL + "/blog" }>Wedding</a>
                <a title="Professional Photographer" href={ process.env.PUBLIC_URL + "/blog" }>Professional Photographer</a>
            </p>
        </div>
    );
};

export default BlogTags;