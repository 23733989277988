import React from 'react';

import PortfolioProjectData from '../../../data/portfolio/EllaAndPaul/portfolioProjectData_EllaAndPaul';

function PortfolioProject() {
    return (
        <section id="about-the-project" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>About the project</h2>
                </div>

                <div className="row gutter-width-md with-pb-md">   
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div className="project-info">
                            <div className="project-info-items">
                                { PortfolioProjectData && PortfolioProjectData.map( ( item, key ) => {
                                    return (
                                        <div key={ key } className="project-info-item">
                                            <div className="project-info-item-content">
                                                <div className="img object-fit">
                                                    <div className="object-fit-cover">
                                                        <img src={ item.imgSrc } alt={ item.title } />
                                                    </div>
                                                </div>

                                                <div className="project-info-item-description">
                                                    <h5 className="project-info-title after">{ item.title }</h5>
                                                    <p className="project-info-description">{ item.description }</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div className="description">
                            <h4 className="max-width-3">Ella & Paul</h4>
                            <p>
                                In the ivy-covered walls of a prestigious law school, two lives became entwined amidst 
                                the legal lexicons and the pursuit of justice. Ella and Paul, both African-American law 
                                students, found themselves drawn together, not only by their shared cultural heritage 
                                but also by a joint aspiration to make a difference in a field often seen as monolithic. 
                                Their initial interaction, sparked by a late-night study session on a landmark civil 
                                rights case, quickly blossomed into a riveting exchange of ideologies and dreams. This 
                                synergy between them went beyond academics; it was a profound recognition of shared 
                                ambitions, resilience, and a collective vision of utilizing law to make transformative 
                                changes in society.
                                <br /><br />
                                Ella and Paul's journey from being law students to prominent attorneys is a remarkable 
                                testament to mutual support, relentless dedication, and their profound love. Amidst 
                                the demanding rigors of law school, their bond strengthened, fortified by shared struggles 
                                and triumphs. Today, they stand together, not just as a couple united in love, but as a 
                                formidable team committed to their shared mission in the legal field. They inspire each 
                                other, pushing boundaries and setting new standards in their pursuit of justice. Their 
                                story is not just one of love, but also a shared dream realized through hard work, 
                                persistence, and a partnership rooted in mutual respect and admiration.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioProject;
