import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/About';
import Contacts from './pages/Contacts';
import Blog from './pages/Blog';
import BlogSinglePost from './pages/BlogSinglePost';
import SearchResults from './pages/SearchResults';
import Portfolio from './pages/Portfolio';
import PortfolioInside from './pages/PortfolioInside';
import page404 from './pages/404';
import UserInterface from './pages/UserInterface';
import Services from './pages/Services';

import SamanthaAndThomas from './pages/SamanthaAndThomas';
import JohnAndKim from './pages/JohnAndKim';
import EllaAndPaul from './pages/ElleAndPaul';
import JoshuaAndLaura from './pages/JoshuaAndLaura';
import FenilAndKinjal from './pages/FenilAndKinjal';
import JohnAndAmy from './pages/JohnAndAmy';
import JohnAndTammy from './pages/JohnAndTammy';
import NatalieAndZac from './pages/NatalieAndZac';
import LeslieAndAndy from './pages/LeslieAndAndy';
import WilliamAndBethany from './pages/WilliamAndBethany';
import StuartAndBrinna from './pages/StuartAndBrinna';
import EddyAndElizabeth from './pages/EddyAndElizabeth';
import PalakAndVarun from './pages/PalakAndVarun';
import JoeAndSara from './pages/JoeAndSara';
import NewshaAndSantiago from './pages/NewshaAndSantiago';

import WeddingBasicsBlog from './pages/WeddingPlanningBasics_BlogPost';
import ShouldYouHirePhotographers from './pages/ShouldYouHirePhotographers_BlogPost';
import WeddingRings from './pages/WeddingRings_BlogPost';
import CowanWeddingVenue from './pages/TheCowanMill_BlogPost';
import VenueOnMain from './pages/TheVenueOnMain_BlogPost';

function App() {
    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/" }` }
                    component={ Home }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/about" }` }
                    component={ About }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/about" }` }
                    component={ About }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/contacts" }` }
                    component={ Contacts }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/blog" }` }
                    component={ Blog }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/Services" }` }
                    component={ Services }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/blog-single-post" }` }
                    component={ BlogSinglePost }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/search-results" }` }
                    component={ SearchResults }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/portfolio" }` }
                    component={ Portfolio }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/ui" }` }
                    component={ UserInterface }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/portfolio-inside" }` }
                    component={ PortfolioInside }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/SamAndTom" }` }
                    component={ SamanthaAndThomas }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/JohnAndKim" }` }
                    component={ JohnAndKim }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/EllaAndPaul" }` }
                    component={ EllaAndPaul }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/JoshuaAndLaura" }` }
                    component={ JoshuaAndLaura }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/FenilAndKinjal" }` }
                    component={ FenilAndKinjal }
                />                
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/JohnAndAmy" }` }
                    component={ JohnAndAmy }
                />               
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/JohnAndTammy" }` }
                    component={ JohnAndTammy }
                />               
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/NatalieAndZac" }` }
                    component={ NatalieAndZac }
                />          
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/LeslieAndAndy" }` }
                    component={ LeslieAndAndy }
                />          
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/WilliamAndBethany" }` }
                    component={ WilliamAndBethany }
                />          
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/StuartAndBrinna" }` }
                    component={ StuartAndBrinna }
                />         
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/EddyAndElizabeth" }` }
                    component={ EddyAndElizabeth }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/PalakAndVarun" }` }
                    component={ PalakAndVarun }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/JoeAndSara" }` }
                    component={ JoeAndSara }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/NewshaAndSantiago" }` }
                    component={ NewshaAndSantiago }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/WeddingPlanningBasics_BlogPost" }` }
                    component={ WeddingBasicsBlog }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/ShouldYouHirePhotographers_BlogPost" }` }
                    component={ ShouldYouHirePhotographers }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/WeddingRings_BlogPost" }` }
                    component={ WeddingRings }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/TheCowanMill_BlogPost" }` }
                    component={ CowanWeddingVenue }
                />
                <Route
                    exact
                    path={ `${ process.env.PUBLIC_URL + "/TheVenueOnMain_BlogPost" }` }
                    component={ VenueOnMain }
                />
                
                <Route exact component={ page404 }/>
            </Switch>
        </Router>
    );
}

export default App;
