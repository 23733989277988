import React from 'react';

const PageTitlePortfolioInside = () => {
    return (
        <section id="page-title" className="block">
            <div className="wrapper">
                <div className="p">
                    <div className="">
                        <img src="https://prayingphotographer.blob.core.windows.net/pictures/mainImgs/PortImgs14_1.jpg" alt="Wedding" />
                    </div>

                    <div className="page-title-description">
                        <div className="title">
                        </div>

                        <div className="description">
                            <p>Newsha & Santiago</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PageTitlePortfolioInside;