import React from 'react';

import PortfolioProjectData from '../../../data/portfolio/JoeAndSara/portfolioProjectData_JoeAndSara';

function PortfolioProject() {
    return (
        <section id="about-the-project" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>About the project</h2>
                </div>

                <div className="row gutter-width-md with-pb-md">   
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div className="project-info">
                            <div className="project-info-items">
                                { PortfolioProjectData && PortfolioProjectData.map( ( item, key ) => {
                                    return (
                                        <div key={ key } className="project-info-item">
                                            <div className="project-info-item-content">
                                                <div className="img object-fit">
                                                    <div className="object-fit-cover">
                                                        <img src={ item.imgSrc } alt={ item.title } />
                                                    </div>
                                                </div>

                                                <div className="project-info-item-description">
                                                    <h5 className="project-info-title after">{ item.title }</h5>
                                                    <p className="project-info-description">{ item.description }</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div className="description">
                            <h4 className="max-width-3">Joe & Sara</h4>
                            <p>
                                Once upon a time in the charming state of Georgia, two young souls crossed paths in the 
                                bustling halls of their middle school. Claire and Mark, both shy and full of innocent 
                                curiosity, found themselves drawn to each other's kind hearts and shared love for 
                                adventure. As they embarked on this journey called life together, their bond grew stronger 
                                with each passing year. From high school sweethearts to college companions, they 
                                supported each other's dreams and aspirations. Their love blossomed like the peach 
                                trees that adorned their home state. With the passage of time, they exchanged heartfelt 
                                vows, promising to be each other's constant in an ever-changing world. Today, as 
                                they proudly stand side by side, Claire and Mark are blessed with three beautiful 
                                children, Joe and Sara. Their love story serves as an inspiration, a testament to 
                                the enduring power of love and the magic that happens when two hearts find each other 
                                amidst the innocence of youth.
                                <br /><br />
                                Amidst the backdrop of Georgia's enchanting landscapes, Claire and Mark's life is a 
                                harmonious blend of laughter, love, and joy. Raising their children with unwavering 
                                devotion, they impart upon them the same values that brought them together so many 
                                years ago. Joe and Sara, with hearts as warm as the Southern sun, embody the best of 
                                both their parents. They witness the love that has stood the test of time, learning 
                                that true happiness lies in the simplest of moments shared with family. Together, the 
                                family of five navigates life's challenges hand in hand, cherishing every memory and 
                                creating new ones as they go. Their tale of love in Georgia's embrace continues, a 
                                story that reminds us all that when love finds its way into two young hearts, it can 
                                create a lifetime of cherished memories and an unbreakable family bond.
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioProject;
