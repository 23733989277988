import React from 'react';

const BlogMeta = () => {
    return (
        <div className="meta">
            <p>
            </p>
        </div>
    );
};

export default BlogMeta;