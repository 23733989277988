import React from 'react';

const PageTitleAbout = () => {
    return (
        <section id="page-title">
            <div className="wrapper">
                <div className="title">
                    <h1 className="large">About</h1>
                </div>

                <div className="description-2">
                    <p>Capturing moments of love and joy, I'm an award-winning 
                    wedding photographer who transforms fleeting whispers of affection 
                    into timeless visual symphonies. With a keen eye and a creative 
                    eart, I weave the raw emotion, laughter, and intimacy of your special 
                    day into images that speak volumes, making your memories not just 
                    unforgettable, but award-winning.</p>
                </div>
            </div>
        </section>
    );
};

export default PageTitleAbout;
