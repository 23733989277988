import React from 'react';

import PortfolioProjectData from '../../../data/portfolio/NatalieAndZac/portfolioProjectData_NatalieAndZac';

function PortfolioProject() {
    return (
        <section id="about-the-project" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>About the Anniversary</h2>
                </div>

                <div className="row gutter-width-md with-pb-md">   
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div className="project-info">
                            <div className="project-info-items">
                                { PortfolioProjectData && PortfolioProjectData.map( ( item, key ) => {
                                    return (
                                        <div key={ key } className="project-info-item">
                                            <div className="project-info-item-content">
                                                <div className="img object-fit">
                                                    <div className="object-fit-cover">
                                                        <img src={ item.imgSrc } alt={ item.title } />
                                                    </div>
                                                </div>

                                                <div className="project-info-item-description">
                                                    <h5 className="project-info-title after">{ item.title }</h5>
                                                    <p className="project-info-description">{ item.description }</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div className="description">
                            <h4 className="max-width-3">Natalie & Zac</h4>
                            <p>
                            Zac and Natalie, a couple whose love had only grown stronger with each passing year, celebrated an 
                            extraordinary anniversary on the sun-kissed beaches of California. With the Pacific waves crashing 
                            gently against the shore as their witness, they embarked on a journey of love and cherished 
                            memories. As they strolled hand in hand along the sandy coastline, their laughter seemed to harmonize 
                            with the rhythm of the ocean, creating a symphony of joy that echoed in the hearts of all who saw them.
                            <br /><br />
                            Their anniversary celebration was a testament to the beauty of their love and the adventures they had 
                            shared together. With a picnic basket filled with their favorite treats and a blanket spread beneath 
                            the shade of a palm tree, they reminisced about the milestones they had reached as a couple. As the 
                            sun dipped below the horizon, painting the sky in hues of orange and pink, they exchanged heartfelt 
                            words of gratitude for the love they had found in each other. As the stars appeared, twinkling like 
                            diamonds in the night sky, Zac and Natalie's love shone brighter than ever, a beacon of hope and 
                            inspiration for all who witnessed their magical anniversary on the breathtaking beaches of California.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioProject;
