import React from 'react';

import PortfolioProjectData from '../../../data/portfolio/NewshaAndSantiago/portfolioProjectData_NewshaAndSantiago';

function PortfolioProject() {
    return (
        <section id="about-the-project" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>About the Wedding</h2>
                </div>

                <div className="row gutter-width-md with-pb-md">   
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <div className="project-info">
                            <div className="project-info-items">
                                { PortfolioProjectData && PortfolioProjectData.map( ( item, key ) => {
                                    return (
                                        <div key={ key } className="project-info-item">
                                            <div className="project-info-item-content">
                                                <div className="img object-fit">
                                                    <div className="object-fit-cover">
                                                        <img src={ item.imgSrc } alt={ item.title } />
                                                    </div>
                                                </div>

                                                <div className="project-info-item-description">
                                                    <h5 className="project-info-title after">{ item.title }</h5>
                                                    <p className="project-info-description">{ item.description }</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <div className="description">
                            <h4 className="max-width-3">Newsha & Santiago</h4>
                            <p>From the moment Newsha and Santiago met at work, a palpable connection emerged between them, a 
                            magnetic pull that neither could easily explain. Their conversations were filled with laughter, 
                            their silences comfortable, and even the simplest interactions felt significant. They went from 
                            sharing lunch breaks to sharing their dreams and fears, eventually realizing that they were each 
                            other's confidants in a world that often felt too big and impersonal. It seemed as if fate had 
                            placed them in the same place at the same time, weaving their individual stories into a shared 
                            narrative of love and companionship.<br /><br />

                            Knowing that he had found the one person who truly understood him, Santiago decided to  propose to Newsha. He 
                            chose a secluded beach in Miami, far from the prying eyes of colleagues and friends. With the gentle waves 
                            serving as their only witness and the setting sun painting the sky with hues of orange and pink, Santiago 
                            dropped to one knee and presented a ring, simple but profoundly beautiful. "Will you marry me?" he asked, 
                            his voice tinged with vulnerability and hope. The world seemed to stand still when Newsha said "Yes," and in 
                            that perfect moment, their love became an eternal secret, a hidden chapter that only they would ever fully 
                            understand.</p>                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioProject;
