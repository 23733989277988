import React from 'react';

const BlogTags = () => {
    return (
        <div className="tags">
            <p>
                <a title="Wedding" href={ process.env.PUBLIC_URL + "/blog" }>Wedding</a>
                <a title="Wedding Venues" href={ process.env.PUBLIC_URL + "/blog" }>Wedding Venues</a>
                <a title="Historic Wedding Venue" href={ process.env.PUBLIC_URL + "/blog" }>Historic Wedding Venues</a>
                <a title="Wedding Day" href={ process.env.PUBLIC_URL + "/blog" }>Wedding Day</a>
            </p>
        </div>
    );
};

export default BlogTags;