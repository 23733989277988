import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleBlogSingle from '../blocks/page-title/VenueOnMainBlog/PageTitleBlogSingle';
import BlogMeta from '../blocks/blog/VenueOnMain/BlogMeta';
import BlogDescription from '../blocks/blog/VenueOnMain/BlogDescription';
import BlogTags from '../blocks/blog/VenueOnMain/BlogTags';
import Widget from '../blocks/widget/Widget';

const BlogSinglePost = () => {
    document.body.classList.add( 'single-post' );

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Dream Wedding Venues</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="The Cowan Mill Acworth, Historic wedding venue Georgia, Acworth event space, Rustic venue near Atlanta, Georgia mill wedding venue, Acworth historic site events, Proctor Creek venue, Downtown Acworth venue, The Cowan Mill wedding, Vintage event space Georgia, Creekside wedding venue, Acworth romantic venue, Historic mill events Georgia, Outdoor wedding Acworth, The Cowan Mill photography, Waterfront event space Georgia, Southern charm venue Acworth, Georgia wedding destinations, Rustic elegance wedding Georgia, Unique venues near Atlanta, Acworth venue with history, Brick venue Georgia wedding, The Cowan Mill receptions, Water's edge events Acworth, Georgia dream wedding venues, Timeless wedding spots Georgia, Creek views wedding Acworth, Acworth landmark events, Historic beauty venue Georgia, The Cowan Mill bridal events" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main">
                <PageTitleBlogSingle />

                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div className="row gutter-width-md">
                                    <div className="col-xl-8 col-lg-8 col-md-12 single-content">
                                        <div className="img object-fit">
                                            <div className="object-fit-cover">
                                                <img src="https://prayingphotographer.blob.core.windows.net/pictures/mainImgs/VenueOnMain.jpg" alt="Wedding Venues" />
                                            </div>
                                        </div>

                                        <BlogMeta />

                                        <div className="title">
                                            <h2>Gem Wedding Venues in Kennesaw</h2>
                                        </div>

                                        <BlogDescription />

                                        <BlogTags />
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-12">
                                        <Widget />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default BlogSinglePost;
