import React from 'react';

const BlogDescription = () => {
    return (
        <div className="description clearfix">
            <p>
            <b>The Venue on Main: Kennesaw's Gem for Weddings</b><br />
            Tucked away in the charming town of Kennesaw, Georgia, The Venue on Main offers an exclusive and elegant setting 
            for your dream wedding. Located conveniently on Main St, this venue blends tradition and modernity, creating the 
            perfect canvas for any celebration.
            <br /><br />
            <b>Exceptional Location</b><br />
            The Venue on Main's central location offers the best of Kennesaw's vibrant community. Guests can explore the local 
            shops, restaurants, and historical sites, all within walking distance. The venue itself is a reflection of the town's 
            charm, with its elegant facade and inviting interiors.
            <br /><br />
            <b>Ceremony Sites</b><br />
            Whether you're dreaming of an intimate garden ceremony or a grand indoor affair, The Venue on Main has the perfect space. 
            Its lush gardens and elegant halls offer a blend of natural beauty and architectural excellence.
            <br /><br />
            <b>Reception Halls</b><br />
            From a cozy reception to a lavish celebration, The Venue on Main's diverse reception areas can accommodate your needs. The 
            attention to detail in decor and design allows for personalization, ensuring that the space reflects your unique style.
            <br /><br />
            <b>Culinary Excellence</b><br />
            Their on-site catering team delivers an extraordinary dining experience. With a focus on fresh, local ingredients, they offer 
            a wide array of menu options to satisfy every taste, whether you prefer traditional Southern fare or international cuisine.
            <br /><br />
            <b>Professional Planning</b><br />
            The Venue on Main's experienced staff is dedicated to making your wedding day flawless. From initial planning to the final farewell, 
            they provide personalized support to ensure every aspect of your event is a success.
            <br /><br />
            <b>Elegant Accommodations</b><br />
            For those in need of accommodations, Kennesaw offers various nearby hotels and lodgings, ensuring that you and your guests have a 
            comfortable stay.
            <br /><br />
            <b>Conclusion</b><br />
            The Venue on Main stands out as a premier wedding destination in Kennesaw, Georgia. Its versatile spaces, exceptional service, 
            and convenient location make it an unparalleled choice for couples looking to celebrate their special day in style. For more 
            information or to book a tour, visit their location on Main St in Kennesaw, or contact them directly. 
            <br /><br />
            You can learn more about this venue by clicking <a href="https://kennesawvenue.com">here</a>.
            </p>
        </div>
    );
};

export default BlogDescription;