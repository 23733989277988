import React from 'react';

import Gallery from '../gallery/Gallery';

const HomePortfolio = () => {
    return (
        <section id="porfolio" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2><a href="/Portfolio">Portfolio</a></h2>
                </div>

                <div className="description max-width-2">
                    <p>Specializing in a candid and journalistic approach, we 
                    capture the natural moments and raw emotions of your wedding 
                    day, telling your unique love story through striking, h
                    igh-quality images.</p>
                </div>

                <div className="spacer p-top-lg">
                    <Gallery />
                </div>
            </div>
        </section>
    );
};

export default HomePortfolio;
